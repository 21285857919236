
























































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import LanguageData from '@/strings';

@Component
export default class Home extends Vue {
  services: {
    name: string;
    loading: boolean;
    isAvailable?: boolean;
    description: string;
    logo: string;
    rules: string[];
  }[] = [];
  searchQueryTextfield = '';
  searchQuery = '';
  headers = [
    { text: 'service', value: 'name', sortable: false },
    { text: 'availability', value: 'isAvailable', sortable: false },
    { text: '', value: '', sortable: false }
  ];
  strings = {
    siteDescTop: '',
    siteDescWhatTitle: '',
    siteDescWhat: '',
    siteDescWhyTitle: '',
    siteDescWhy: '',
    siteDescSupportTitle: '',
    siteDescContribTitle: '',
    siteDescContrib: '',
    resultDesc: ''
  };

  // get isLoading(): boolean {
  //   return this.services.find(el => el.loading);
  // }

  get lang() {
    return Vue.prototype.lang;
  }

  get rate(): number | null {
    if (this.services.find(el => el.loading)) {
      return null;
    } else {
      let num = 0;
      this.services.forEach(el => {
        if (el.isAvailable) num++;
      });
      const total = this.services.length;
      return num / total;
    }
  }

  async search() {
    this.searchQuery = this.searchQueryTextfield;

    if (this.services.length === 0) {
      await this.loadServices();
    }

    this.services.forEach(async service => {
      try {
        service.loading = true;

        const res = await axios.get(
          // `http://localhost:3000/api/v1/validates/${service.name}`,
          `https://usrcheck.com/api/v1/validates/${service.name}`,
          {
            params: {
              userId: this.searchQuery
            }
          }
        );
        if (res.data.userExists) {
          service.isAvailable = false;
          service.description = this.languageData('result_user_exists');
        } else if (!res.data.idValid) {
          service.isAvailable = false;
          service.description = res.data.description[Vue.prototype.lang];
        } else {
          service.isAvailable = true;
          service.description = this.languageData('result_available');
        }
      } catch {
        service.isAvailable = undefined;
        service.description = this.languageData('result_error');
      } finally {
        service.loading = false;
      }
    });
  }

  async loadServices() {
    try {
      const res = await axios.get('https://usrcheck.com/api/v1/validates');
      const { data } = res;
      data.services.forEach((service: any) => {
        this.services.push({
          name: service.name,
          loading: false,
          isAvailable: undefined,
          description: '',
          logo: service.logo,
          rules: service.rules.map(el => el.description[Vue.prototype.lang])
        });
      });
    } catch (err) {
      // TODO
    }
  }

  created() {
    this.loadServices();

    this.strings.siteDescTop = this.languageData('home1');
    this.strings.siteDescWhatTitle = this.languageData('home2title');
    this.strings.siteDescWhat = this.languageData('home2');
    this.strings.siteDescWhyTitle = this.languageData('home3title');
    this.strings.siteDescWhy = this.languageData('home3');
    this.strings.siteDescSupportTitle = this.languageData('home4title');
    this.strings.siteDescContribTitle = this.languageData('home5title');
    this.strings.siteDescContrib = this.languageData('home5');
    this.strings.resultDesc = this.languageData('result_desc');
  }

  languageData(id: string): string {
    const lang: 'en' | 'ja' = this.lang;
    return LanguageData.filter(el => el.id == id)[0]['strings'][lang];
  }
}
