export default [
  {
    id: 'home1',
    strings: {
      en:
        'Enter any username to check for its availability throughout many services!',
      ja:
        '使いたいユーザーネームを入力すると、様々なサービスで使用可能か判定します'
    }
  },
  {
    id: 'home2title',
    strings: {
      en: "What's this?",
      ja: 'これはなに？'
    }
  },
  {
    id: 'home2',
    strings: {
      en:
        'Enter any username you wish to use newly for web services! We will check if that username is available to use or not in supported services, by validating if it matches required format or checking no user already uses that username. ',
      ja:
        '指定されたユーザー名が、対応サービス一覧に記載されているウェブサービスで新規に使えるかどうかを、「サービス指定の条件に適合するか」「すでに使われていないか」一括で判定をします。'
    }
  },
  {
    id: 'home3title',
    strings: {
      en: 'Why this?',
      ja: 'なんのため？'
    }
  },
  {
    id: 'home3',
    strings: {
      en:
        'This is for people who want to use same username throughout many web services. This website will allow you to check availability quickly.',
      ja:
        '複数のウェブサービスで一貫して同じユーザー名を使いたい人向けです。まとめて瞬時に判定をするので、早く簡単に有効なユーザー名を見つけることができます'
    }
  },
  {
    id: 'home4title',
    strings: {
      en: 'Supported Services',
      ja: '対応サービス'
    }
  },
  {
    id: 'home5title',
    strings: {
      en: 'Contribution',
      ja: 'コントリビューション'
    }
  },
  {
    id: 'home5',
    strings: {
      en:
        'This website is open source. I appreciate any contributions such as adding supported services, adding new language support, reporting bugs, etc.',
      ja:
        'TwitterやGitHubでお気軽にご要望ください。対応サービスの追加、言語対応、バグ報告などのイシューやプルリクを頂けると大変嬉しいです。'
    }
  },
  {
    id: 'result_desc',
    strings: {
      en: 'Availability for username @{USERNAME} is...',
      ja: '@{USERNAME}が使えるサービスは...'
    }
  },
  {
    id: 'result_user_exists',
    strings: {
      en: 'This username is already taken',
      ja: 'すでに使われています'
    }
  },
  {
    id: 'result_available',
    strings: {
      en: 'This username is for you!',
      ja: '使用可能です！'
    }
  },
  {
    id: 'result_error',
    strings: {
      en: 'Sorry, something went wrong. Try again!',
      ja: '不明なエラーが発生しました。再度お試しください'
    }
  }
];
